import React from "react";
import styled, { keyframes } from "styled-components";
import ArrowRight from "../../images/svg/arrow-right.svg";
import {
  body,
  breakpoints,
  colors,
  h3,
  Link,
  ScreenHeight75,
  sizes,
} from "../../models";

const EinleitungContainer = styled.div`
  padding-top: 30vh;
`;

const TextContainer = styled.div`
  background: ${colors.orangeOverlay70};
  padding: ${sizes.fine}px ${sizes.base}px 0 ${sizes.base}px;
  border-top: 1px solid ${colors.gridLight};
  border-bottom: 1px solid ${colors.gridLight};
  backdrop-filter: blur(6px);

  @media (min-width: ${breakpoints.textBox}px) {
    border: 1px solid ${colors.gridLight};
    border-radius: ${sizes.fine}px;
  }

  @media (min-width: ${breakpoints.desktopS}px) {
    background: none;
    border: none;
    backdrop-filter: none;
    padding: 0 ${sizes.twice}px ${sizes.petite}px ${sizes.petite}px;
  }
`;

const BodyText = styled.div`
  ${body};
  p {
    margin: 0;
    padding: ${sizes.fine}px 0 ${sizes.petite}px 0;
  }
`;

const Einleitung = () => {
  return (
    <EinleitungContainer>
      <TextContainer id={"Einleitung"}>
        <BodyText>
          <p>
            Climate change has a direct impact on the intensity and frequency of
            heavy rainfall and heat waves. This is shown by the{" "}
            <Link
              href="https://www.raonline.ch/pages/edu/pdfCLI/IPCC_AR6WGI2101.pdf"
              target="_blank"
            >
              IPCC reports from 2021
            </Link>
            . This data story shows extreme weather events between 2000 and 2021
            caused by high temperatures and precipitation. The data comes from
            the{" "}
            <Link href="https://opendata.dwd.de/" target="_blank">
              German Weather Service
            </Link>{" "}
            and a{" "}
            <Link
              href="https://www.prognos.com/de/projekt/bezifferung-von-klimafolgekosten-deutschland"
              target="_blank"
            >
              study on climate damage
            </Link>{" "}
            commissioned by the German Federal Ministry of Economics and Climate
            Protection.
          </p>
          <p>
            You can leave the story at any time and use the stepper below the
            map to select an event you are interested in. Have fun!
          </p>
        </BodyText>
        <span id="waypoint" data-selected="title"></span>
      </TextContainer>
      <ScreenHeight75 />
    </EinleitungContainer>
  );
};

export default Einleitung;
