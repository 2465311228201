import { css } from "styled-components";
import { breakpoints, gridMargin, gutterWidths } from ".";

const gridTextSettings = css`
  z-index: 5;
`;

export const gridContainer = css`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: ${gutterWidths.smartphone}px;
  padding: 0 ${gridMargin.smartphone}px 0 ${gridMargin.smartphone}px;
  max-width: 1920px;
  margin: auto;
  @media (min-width: ${breakpoints.tabletL}px) {
    grid-template-columns: repeat(12, 1fr);
    grid-gap: ${gutterWidths.tabletL}px;
    padding: 0 ${gridMargin.tabletL}px 0 ${gridMargin.tabletL}px;
  }
  @media (min-width: ${breakpoints.desktopS}px) {
    grid-gap: ${gutterWidths.desktopS}px;
    padding: 0 ${gridMargin.desktopS}px 0 ${gridMargin.desktopS}px;
  }
  @media (min-width: ${breakpoints.desktopL}px) {
    grid-gap: ${gutterWidths.desktopL}px;
    padding: 0 ${gridMargin.desktopL}px 0 ${gridMargin.desktopL}px;
  }
  @media (min-width: ${breakpoints.desktopXL}px) {
    grid-gap: ${gutterWidths.desktopXL}px;
    padding: 0 ${gridMargin.desktopXL}px 0 ${gridMargin.desktopXL}px;
  }
`;

export const textLeftGrid = css`
  ${gridTextSettings};
  grid-column: 1 / 7;
  @media (min-width: ${breakpoints.tabletL}px) {
    grid-column: 3 / 11;
  }
  @media (min-width: ${breakpoints.desktopS}px) {
    grid-column: 1 / 6;
  }
  @media (min-width: ${breakpoints.desktopL}px) {
    grid-column: 1 / 6;
  }
  @media (min-width: ${breakpoints.desktopXL}px) {
    grid-column: 1 / 5;
  }
`;

export const textRightGrid = css`
  ${gridTextSettings};
  grid-column: 1 / 7;
  @media (min-width: ${breakpoints.tabletL}px) {
    grid-column: 3 / 11;
  }
  @media (min-width: ${breakpoints.desktopS}px) {
    grid-column: 9 / 13;
  }
  @media (min-width: ${breakpoints.desktopL}px) {
    grid-column: 9 / 13;
  }
  @media (min-width: ${breakpoints.desktopXL}px) {
    grid-column: 9 / 13;
  }
`;
