import React, { useState, useRef, useEffect } from "react";
import ReactSlider from "react-slider";
import styled, { css, keyframes } from "styled-components";
import {
  breakpoints,
  buttonStyle,
  colors,
  fonts,
  label,
  shadowFilter,
} from "../../models";
import Histogram from "./histogram";
import "./slider.css";

interface SliderProps {
  selectedData: any;
  sliderValue: number;
  handleSliderValue: any;
  setPlaying: React.Dispatch<React.SetStateAction<boolean>>;
  setShowHand: React.Dispatch<React.SetStateAction<boolean>>;
  showHand: boolean;
}

const TimeSlider = styled.div`
  height: 59px;
  width: 360px;
  margin: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
`;

const ThumbContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const thumbAnimation = keyframes`
0% {box-shadow: 0px 0px 0px 0px ${colors.blackOverlay}};
100% {box-shadow: 0px 0px 0px 8px transparent};
`;

const Thumb = styled.div<any>`
  ${buttonStyle}
  width: 20px;
  height: 20px;
  border-radius: 50px;
  animation: ${(props) =>
    props.showHand
      ? css`
          ${thumbAnimation} 2s infinite
        `
      : ""};

  &:hover {
    cursor: pointer;
    filter: ${shadowFilter.slider};
  }

  &:active {
    filter: ${shadowFilter.slider};
  }
`;

const CurrentDate = styled.div`
  ${label};
  font-weight: ${fonts.weightBold};
  text-align: center;
  @media (min-width: ${breakpoints.desktopS}px) {
    font-weight: ${fonts.weightBold};
  }
`;

export const Slider = ({
  selectedData,
  sliderValue,
  handleSliderValue,
  setPlaying,
  setShowHand,
  showHand,
}: SliderProps) => {
  const [startDate, setStartDate] = useState<Date>(new Date(2000, 0));
  const [endDate, setEndDate] = useState<Date>(new Date(2000, 0));
  const [dayRange, setDayRange] = useState<number>(10);

  useEffect(() => {
    selectedData && calculateDays(selectedData.Beginn, selectedData.Ende);
  }, [selectedData]);

  const calculateDays = (start: string, end: string) => {
    const startDate = new Date(start);
    const endDate = new Date(end);
    const differenceInTime = endDate.getTime() - startDate.getTime();
    const differenceInDays = differenceInTime / (1000 * 3600 * 24);

    setStartDate(startDate);
    setEndDate(endDate);
    setDayRange(differenceInDays);
  };

  const currentDate = (days: number) => {
    const monthNames = [
      "Jan",
      "Feb",
      "März",
      "Apr",
      "Mai",
      "Jun",
      "Jul",
      "Aug",
      "Sept",
      "Okt",
      "Nov",
      "Dez",
    ];
    let date = new Date(selectedData.Beginn);
    date.setDate(date.getDate() + days);

    let day: any = date.getDate();
    let month: any = date.getMonth() + 1;
    let year: any = date.getFullYear();

    day = day.toString().padStart(2, "0");
    month = month.toString().padStart(2, "0");
    year = year.toString().padStart(2, "0");
    return `${day}/${month}/${year}`;
  };

  return (
    <>
      <TimeSlider>
        <Histogram
          selectedData={selectedData}
          startDate={startDate}
          endDate={endDate}
          dayRange={dayRange}
        />
        <ReactSlider
          className="timeSlider"
          trackClassName="timeSlider-track"
          marks
          markClassName="example-mark"
          min={0}
          max={dayRange}
          thumbClassName="timeSlider-thumb"
          renderThumb={(props, state) => (
            <div {...props}>
              <ThumbContainer>
                <Thumb showHand={showHand}></Thumb>
                <CurrentDate>
                  {selectedData && currentDate(state.valueNow)}
                </CurrentDate>
              </ThumbContainer>
            </div>
          )}
          onChange={(e) => {
            setShowHand(false);
            setPlaying(false);
            handleSliderValue(Math.round(e));
          }}
          value={sliderValue}
        />
      </TimeSlider>
    </>
  );
};
