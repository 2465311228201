import styled, { css } from "styled-components";
import {
  fonts,
  fontSizes,
  breakpoints,
  lineHeights,
  colors,
  shadowFilter,
} from "../models";

export const buttonStyle = css`
  background-color: ${colors.white};
  border: 1px solid ${colors.black};
  &:hover {
    background-color: ${colors.buttonHover};
    cursor: pointer;
  }
`;

export const cueContainerStyle = css`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 180px;
  z-index: 1000;
`;

export const cueStyle = css`
  background: ${colors.white80};
  padding: 5px 12px;
  border-radius: 5px;
  filter: ${shadowFilter.cue};
  backdrop-filter: blur(2px);
`;

export const ScreenHeight = styled.div`
  height: 100vh;
`;

export const ScreenHeight80 = styled.div`
  height: 80vh;
`;

export const ScreenHeight75 = styled.div`
  height: 75vh;
`;

export const ScreenHeight25 = styled.div`
  height: 25vh;
`;

export const Link = styled.a`
  text-decoration: none;
  cursor: pointer;
  color: ${colors.black};
  padding-bottom: 1px;
  border-bottom: 1.5px dashed ${colors.black};
  transition: border-bottom 0.5s;

  &:hover {
    border-bottom: none;
  }
`;
