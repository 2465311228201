import React, { useRef, useEffect } from "react";
import { select, axisBottom, scaleLinear } from "d3";
import { colors } from "../../models";

interface AxisXProps {
  id: string;
  hoveredId: string | undefined;
  type: string;
  selected: boolean;
  visited: boolean;
  opacityVisited: number;
}

export const Icon = ({
  id,
  hoveredId,
  type,
  selected,
  visited,
  opacityVisited,
}: AxisXProps) => {
  const createHeatIcon = () => {
    return (
      <>
        <path
          className={`${id}-icon`}
          d="M8.99896 12.5108C8.82436 12.5108 8.64796 12.4514 8.50486 12.329C8.18266 12.0554 8.14306 11.573 8.41666 11.2508C8.81446 10.7819 9.10156 10.4435 9.10156 9.70276C9.10156 8.96206 8.81446 8.62366 8.41666 8.15476C7.94956 7.60396 7.36816 6.91906 7.36816 5.61676C7.36816 4.31446 7.94956 3.62956 8.41666 3.07966C8.69026 2.75746 9.17266 2.71786 9.49486 2.99146C9.81706 3.26506 9.85666 3.74746 9.58306 4.06966C9.18526 4.53856 8.89816 4.87696 8.89816 5.61766C8.89816 6.35836 9.18526 6.69676 9.58306 7.16566C10.0502 7.71646 10.6316 8.40136 10.6316 9.70366C10.6316 11.006 10.0502 11.6909 9.58306 12.2417C9.43186 12.4199 9.21586 12.5117 8.99896 12.5117V12.5108Z"
          fill={selected || id === hoveredId ? colors.white : colors.heat}
          fillOpacity={visited && !selected ? opacityVisited : 1}
        />
        <path
          className={`${id}-icon`}
          d="M5.36615 12.5198C5.19155 12.5198 5.01515 12.4604 4.87205 12.338C4.54985 12.0644 4.51025 11.582 4.78385 11.2598C5.18165 10.7909 5.46875 10.4525 5.46875 9.71179C5.46875 8.97109 5.18165 8.63269 4.78385 8.16379C4.31675 7.61299 3.73535 6.92809 3.73535 5.62579C3.73535 4.32349 4.31675 3.63859 4.78385 3.08869C5.05745 2.76649 5.53985 2.72689 5.86205 3.00049C6.18425 3.27409 6.22385 3.75649 5.95025 4.07869C5.55245 4.54759 5.26535 4.88599 5.26535 5.62669C5.26535 6.36739 5.55245 6.70579 5.95025 7.17469C6.41735 7.72549 6.99875 8.41039 6.99875 9.71269C6.99875 11.015 6.41735 11.6999 5.95025 12.2507C5.79905 12.4289 5.58305 12.5207 5.36615 12.5207V12.5198Z"
          fill={selected || id === hoveredId ? colors.white : colors.heat}
          fillOpacity={visited && !selected ? opacityVisited : 1}
        />
        <path
          className={`${id}-icon`}
          d="M12.6328 12.5108C12.4582 12.5108 12.2818 12.4514 12.1387 12.329C11.8165 12.0554 11.7769 11.573 12.0505 11.2508C12.4483 10.7819 12.7354 10.4435 12.7354 9.70276C12.7354 8.96206 12.4483 8.62366 12.0505 8.15476C11.5834 7.60396 11.002 6.91906 11.002 5.61676C11.002 4.31446 11.5834 3.62956 12.0505 3.07966C12.3241 2.75746 12.8065 2.71786 13.1287 2.99146C13.4509 3.26506 13.4905 3.74746 13.2169 4.06966C12.8191 4.53856 12.532 4.87696 12.532 5.61766C12.532 6.35836 12.8191 6.69676 13.2169 7.16566C13.684 7.71646 14.2654 8.40136 14.2654 9.70366C14.2654 11.006 13.684 11.6909 13.2169 12.2417C13.0657 12.4199 12.8497 12.5117 12.6328 12.5117V12.5108Z"
          fill={selected || id === hoveredId ? colors.white : colors.heat}
          fillOpacity={visited && !selected ? opacityVisited : 1}
        />
        <path
          className={`${id}-icon`}
          d="M14.6135 15.5464H3.38512C2.96302 15.5464 2.62012 15.2035 2.62012 14.7814C2.62012 14.3593 2.96302 14.0164 3.38512 14.0164H14.6144C15.0365 14.0164 15.3794 14.3593 15.3794 14.7814C15.3794 15.2035 15.0365 15.5464 14.6144 15.5464H14.6135Z"
          fill={selected || id === hoveredId ? colors.white : colors.heat}
          fillOpacity={visited && !selected ? opacityVisited : 1}
        />
      </>
    );
  };

  const createRainIcon = () => {
    return (
      <path
        className={`${id}-icon`}
        d="M9.00121 4.56153C9.46921 5.00253 10.092 5.64693 10.7067 6.42813C11.9127 7.96083 12.576 9.39723 12.576 10.4709C12.576 11.7246 12.2025 12.6939 11.4663 13.3509C10.8336 13.9152 9.95791 14.2266 9.00121 14.2266C8.04361 14.2266 7.16791 13.9152 6.53521 13.3509C5.79901 12.6939 5.42551 11.7246 5.42551 10.4709C5.42551 9.39723 6.08971 7.96083 7.29481 6.42813C7.90951 5.64693 8.53321 5.00253 9.00031 4.56153M9.00031 2.59953C8.75101 2.59953 3.89551 6.79623 3.89551 10.4718C3.89551 14.1474 6.51541 15.7575 9.00031 15.7575C11.4852 15.7575 14.1051 14.1483 14.1051 10.4718C14.1051 6.79533 9.25051 2.59863 9.00121 2.59863L9.00031 2.59953Z"
        fill={selected || id === hoveredId ? colors.white : colors.rain}
        fillOpacity={visited && !selected ? opacityVisited : 1}
      />
    );
  };

  return (
    <svg width="18" height="19">
      {type === "Hitzesommer" ? createHeatIcon() : createRainIcon()}
    </svg>
  );
};
