import React, { useRef, useEffect } from "react";
import mapboxgl, { LngLatLike } from "mapbox-gl";
import * as d3 from "d3";
import { circleRadiusScale, colors } from "../../models";
import { scaleLinear, scaleQuantile, scaleQuantize } from "d3";

interface StationProps {
  map: any;
  date: Date;
  station: string;
  stationData: any;
  data: any;
  setSelectedStation: React.Dispatch<React.SetStateAction<string>>;
  selectedCategory: string | undefined;
  setTooltipPosition: React.Dispatch<React.SetStateAction<[number, number]>>;
  setTooltipVisible: React.Dispatch<React.SetStateAction<boolean>>;
  stationsVisible: boolean;
}

const Station = ({
  map,
  date,
  station,
  stationData,
  data,
  setSelectedStation,
  selectedCategory,
  setTooltipPosition,
  setTooltipVisible,
  stationsVisible,
}: StationProps) => {
  const markerRef = useRef<any>(null);
  const dateOffset = date?.getTimezoneOffset();
  const dateToTransform = new Date(date.getTime() - dateOffset * 60 * 1000);
  const yyyymmdd = dateToTransform
    .toISOString()
    .split("T")[0]
    .replace(/-/g, "");
  // const yearDefined = data ? data[String(yyyymmdd)] : undefined;
  const stationLongLat: LngLatLike = [stationData.long, stationData.lat];

  // add marker to map
  useEffect(() => {
    const marker = new mapboxgl.Marker(markerRef.current)
      .setLngLat(stationLongLat)
      .addTo(map);
    return () => {
      marker.remove();
    };
  }, []);

  const handleMouseMove = (e: any) => {
    const target: any = e.target;
    target.setAttribute("fill-opacity", 0.5);
    setTooltipVisible(true);
    setTooltipPosition([e.clientX, e.clientY]);
    setSelectedStation(target.dataset.station);
  };

  const createStation = () => {
    const value = data ? data[String(yyyymmdd)] : undefined;
    const isTemperature = selectedCategory === "temperature";
    const domain = isTemperature
      ? circleRadiusScale.domainHeat
      : circleRadiusScale.domainRain;
    const range = isTemperature
      ? circleRadiusScale.rangeHeat
      : circleRadiusScale.rangeRain;
    const scaleRadius = d3.scaleSqrt().domain(domain).range(range);
    const circleRadius = value != undefined ? scaleRadius(value) : 0;
    const stationColor =
      value != undefined
        ? isTemperature
          ? colors.heat
          : colors.rain
        : colors.transparent;
    const svgSize = circleRadius >= 2 ? circleRadius * 2 + 1 : 2 * 2 + 1;

    return (
      <svg width={svgSize} height={svgSize}>
        <circle
          data-station={station}
          cx={svgSize / 2}
          cy={svgSize / 2}
          r={1}
          fill={circleRadius > 2 ? stationColor : "transparent"}
          fillOpacity={1}
        />
        <circle
          data-station={station}
          cx={svgSize / 2}
          cy={svgSize / 2}
          r={circleRadius}
          fill={isTemperature ? colors.heat : colors.rain}
          fillOpacity={0.2}
          stroke={isTemperature ? colors.heat : colors.rain}
          strokeWidth={0.3}
          onMouseMove={(e) => {
            handleMouseMove(e);
          }}
          onMouseOut={(e) => {
            const target: any = e.target;
            target.setAttribute("fill-opacity", 0.2);
            setTooltipVisible(false);
          }}
        />
      </svg>
    );
  };

  return (
    <>
      <div
        ref={markerRef}
        style={{ display: stationsVisible ? "block" : "none" }}
      >
        {createStation()}
      </div>
    </>
  );
};

export default Station;
