import React, { useRef, useEffect, useState } from "react";
import mapboxgl, { LngLatLike } from "mapbox-gl";
import CueArrow from "../../images/svg/cue-arrow-up.svg";
import Video from "../../images/svg/video.svg";
import {
  annotation,
  breakpoints,
  buttonStyle,
  colors,
  cueContainerStyle,
  cueStyle,
  fonts,
  label,
} from "../../models";
import styled from "styled-components";

interface VideoMarkerProps {
  name: string;
  index: number;
  map: any;
  linkObject: any;
  show: boolean;
  setVideoOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setVideoData: React.Dispatch<React.SetStateAction<string>>;
  cueVisible: boolean;
  setCueVisible: React.Dispatch<React.SetStateAction<boolean>>;
  overlayOpen: boolean;
  width: number;
}

const CircleButton = styled.div<any>`
  ${buttonStyle};
  pointer-events: auto;
  border-radius: 30px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  width: 32px;
  height: 32px;
  background: ${colors.white};
  transition: opacity 0.5s;
`;

const Name = styled.div<any>`
  ${label};
  font-weight: ${fonts.weightBold};
  position: absolute;
  top: 7px;
  left: 38px;
  white-space: nowrap;

  @media (min-width: ${breakpoints.desktopS}px) {
    font-weight: ${fonts.weightBold};
  }
`;

const NameBackground = styled.div<any>`
  ${label};
  font-weight: ${fonts.weightBold};
  color: ${colors.white};
  -webkit-text-stroke: 4px ${colors.white80};
  position: absolute;
  top: 7px;
  left: 38px;
  white-space: nowrap;

  @media (min-width: ${breakpoints.desktopS}px) {
    font-weight: ${fonts.weightBold};
  }
`;

const CueContainer = styled.div<any>`
  ${cueContainerStyle}
  transform: translate(-50%, 0);
  margin-left: -22px;
  display: none;

  @media (min-width: ${breakpoints.tabletL}px) {
    display: ${(props) => (props.isVisible ? "flex" : "none")};
  }
`;

const Cue = styled.div`
  ${annotation};
  ${cueStyle}
`;

const VideoMarker = ({
  name,
  index,
  map,
  linkObject,
  show,
  setVideoOpen,
  setVideoData,
  cueVisible,
  setCueVisible,
  overlayOpen,
  width,
}: VideoMarkerProps) => {
  const markerRef = useRef<any>(null);
  const stationLongLat: LngLatLike = linkObject.coordinates;

  const handleMouseClick = (e: any) => {
    setCueVisible(false);
    setVideoOpen(true);
    setVideoData(`${linkObject.link}${linkObject.start}&autoplay=1&muted=1`);
  };

  // add marker to map
  useEffect(() => {
    const marker = new mapboxgl.Marker(markerRef.current)
      .setLngLat(stationLongLat)
      .addTo(map);

    return () => {
      marker.remove();
    };
  }, []);

  const createEvents = () => {
    return (
      <>
        <svg width="32px" height="32px">
          <g transform={"translate(7.5,7.5)"}>
            <Video />
          </g>
        </svg>
      </>
    );
  };
  return (
    <CircleButton
      className={"checker"}
      ref={markerRef}
      style={{
        opacity: `${
          show ? (width >= breakpoints.desktopS ? 1 : !overlayOpen ? 1 : 0) : 0
        }`,
        pointerEvents: `${show ? "auto" : "none"}`,
      }}
      onClick={handleMouseClick}
    >
      {createEvents()}
      <NameBackground overlayOpen={overlayOpen}>{name}</NameBackground>
      <Name overlayOpen={overlayOpen}>{name}</Name>
      <CueContainer
        isVisible={index === 0 && cueVisible && name != "Rekord in Euskirchen"}
      >
        <CueArrow />
        <Cue>Watch a video clip about this place.</Cue>
      </CueContainer>
    </CircleButton>
  );
};

export default VideoMarker;
