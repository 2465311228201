import { css } from "styled-components";
import { fonts, fontSizes, breakpoints, lineHeights } from "../models";

export const baseFontProps = {
  "font-family": fonts.base,
  "font-weight": fonts.weightRegular,
  "font-size": fontSizes.base,
  "line-height": `${lineHeights.body}`,
};

export const titleFontProps = {
  "font-family": fonts.base,
  "font-weight": fonts.weightBlack,
  "font-size": fontSizes.base,
  "font-stretch": fonts.fontStretchExtended,
};

// -03 macro
export const macroRegular = {
  ...baseFontProps,
  "font-size": fontSizes.macro,
};

// -02 micro
export const microRegular = {
  ...baseFontProps,
  "font-size": fontSizes.micro,
};

// -01 small
export const smallRegular = {
  ...baseFontProps,
  "font-size": fontSizes.small,
};

export const smallBold = {
  ...smallRegular,
  "font-weight": fonts.weightBold,
};

export const smallTitle = {
  ...titleFontProps,
  "font-size": fontSizes.small,
};

// 00 base
export const baseRegular = {
  ...baseFontProps,
};

export const baseTitle = {
  ...titleFontProps,
};

// +01 midSize
export const midSizeRegular = {
  ...baseFontProps,
  "font-size": fontSizes.midSize,
};

export const midSizeTitle = {
  ...titleFontProps,
  "font-size": fontSizes.midSize,
};

// +02 big
export const bigRegular = {
  ...baseFontProps,
  "font-size": fontSizes.big,
};

export const bigTitle = {
  ...titleFontProps,
  "font-size": fontSizes.big,
};

// +03 large
export const largeRegular = {
  ...baseFontProps,
  "font-size": fontSizes.large,
};

export const largeTitle = {
  ...titleFontProps,
  "font-size": fontSizes.large,
};

// +04 huge
export const hugeRegular = {
  ...baseFontProps,
  "font-size": fontSizes.huge,
};

// +05 gigantic
export const giganticRegular = {
  ...baseFontProps,
  "font-size": fontSizes.gigantic,
};

// +06 mega
export const megaBlackCondensed = {
  ...titleFontProps,
  "font-size": fontSizes.mega,
  "line-height": "1em",
};

// STORY
/*
  {
    macro: "8.192px",
    micro: "10.24px",
    small: "12.8px",
    base: "16px",
    midSize: "20px",
    big: "25px",
    large: "31.25px",
    huge: "39.0625px",
    gigantic: "48.828125px"
    mega: "61.04px"
  }
*/

export const h2 = css`
  ${bigTitle};
  @media (min-width: ${breakpoints.tabletL}px) {
    ${largeTitle};
  }
`;

export const h3 = css`
  ${midSizeTitle};
  @media (min-width: ${breakpoints.desktopS}px) {
    ${midSizeTitle};
  }
`;

export const body = css`
  ${baseRegular};
  @media (min-width: ${breakpoints.desktopS}px) {
    ${baseRegular};
  }
`;

export const button = css`
  ${smallBold};
  @media (min-width: ${breakpoints.desktopS}px) {
    ${smallBold};
  }
`;

export const label = css`
  ${smallRegular};
  @media (min-width: ${breakpoints.desktopS}px) {
    ${smallRegular};
  }
`;

export const fatLabel = css`
  ${smallTitle};
  @media (min-width: ${breakpoints.desktopS}px) {
    ${smallTitle};
  }
`;

export const tooltipHeadline = css`
  ${baseTitle};
  @media (min-width: ${breakpoints.desktopS}px) {
    ${baseTitle};
  }
`;

export const annotation = css`
  ${smallRegular};
  font-variation-settings: "slnt" -10;
  @media (min-width: ${breakpoints.desktopS}px) {
    ${smallRegular};
  }
`;
