import { scaleLinear } from "d3";
import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import clouds from "../images/clouds-shadow.png";

interface CloudsProps {
  selectedEvents: any;
  zoom: number;
  zoomMultiplicator: number;
}

const CloudContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  z-index: 1;
  pointer-events: none;
`;

const cloudAnimation = keyframes`
0%{transform: translate(-20vh,0)}
50%{transform: translate(-120vh,100vh)}
100%{transform: translate(-20vh,0)}
`;

const CloudImage = styled.img`
  position: absolute;
  bottom: 0;
  left: 0;
  animation: ${cloudAnimation} 300s infinite linear;

  transition: opacity 0.4s;
`;

const Clouds = ({ selectedEvents, zoom, zoomMultiplicator }: CloudsProps) => {
  const [imageWidth, setImageWidth] = useState<number>(2500);
  const [imageScale, setImageScale] = useState<number>(1);

  useEffect(() => {
    if (zoom === 0 || zoomMultiplicator === 0) return;
    console.log(zoomMultiplicator);
    const widthScale = scaleLinear()
      .domain([4, 5 * zoomMultiplicator, 8 * zoomMultiplicator])
      .range([3000, 3000, 4000]);
    const imageScaleScale = scaleLinear()
      .domain([4, 5 * zoomMultiplicator, 8 * zoomMultiplicator])
      .range([1, 1, 4 / 3]);
    setImageWidth(widthScale(zoom));
    setImageScale(imageScaleScale(zoom));
  }, [zoom]);

  return (
    <CloudContainer>
      <CloudImage
        src={clouds}
        style={{
          opacity:
            selectedEvents &&
            selectedEvents.isTitle &&
            zoom <= 5 * zoomMultiplicator + 1
              ? "1"
              : "0",
          width: zoom > 5 * zoomMultiplicator ? `${imageWidth}px` : "3000px",
          scale: zoom > 5 * zoomMultiplicator ? `${imageScale}` : "1",
        }}
      />
    </CloudContainer>
  );
};

export default Clouds;
