import React, { useRef, useEffect } from "react";
import { select, axisBottom, scaleLinear } from "d3";
import { colors, fonts, fontSizes } from "../../models";

interface AxisXProps {
  scaleX: any;
  chartWidth: number;
}

export const AxisX = ({ scaleX, chartWidth }: AxisXProps) => {
  const ref = useRef<any>();

  useEffect(() => {
    const xAxisG = select(ref.current);
    const xAxis = axisBottom(scaleX)
      .ticks(3)
      .tickSizeOuter(0)
      .tickFormat((d: any) => d);

    xAxisG
      .append("line")
      .attr("x1", 0)
      .attr("y1", 0)
      .attr("x1", chartWidth)
      .attr("y1", 0)
      .style("stroke-width", "4px")
      .style("stroke", colors.white)
      .style("stroke-opacity", 0.8)
      .style("stroke-linecap", "round");

    xAxisG
      .call(xAxis)
      .call((g) => g.select(".domain").attr("color", colors.gridLight))
      .call((g) =>
        g
          .selectAll(".tick")
          .selectAll("line")
          .attr("class", "background-ticks")
          .attr("y1", 2)
          .attr("y2", 7)
          .style("stroke", colors.white)
          .style("stroke-width", "2.5px")
          .style("stroke-linecap", "round")
      )
      .call((g) =>
        g
          .selectAll(".tick")
          .append("line")
          .attr("y1", 2)
          .attr("y2", 7)
          .style("stroke", colors.gridLight)
      )
      .call((g) =>
        g
          .selectAll("text")
          .attr("color", colors.grid)
          .attr("dy", "1em")
          .style("font-family", fonts.base)
          .style("font-size", fontSizes.small)
          .style("stroke-width", "4px")
          .style("paint-order", "stroke")
          .style("stroke", colors.white)
          .style("stroke-opacity", 0.8)
      );
  }, []);

  return <g ref={ref} transform="translate(0,60)" />;
};
