import React from "react";
import styled, { keyframes } from "styled-components";
import ArrowRight from "../../images/svg/arrow-right.svg";
import titleText from "../../images/title-text-en.png";
import {
  body,
  breakpoints,
  colors,
  fontSizes,
  label,
  sizes,
  titleFontProps,
} from "../../models";
import Einleitung from "./einleitung";

const TitleContainer = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 80vh;
`;

const TextContainer = styled.div`
  background: ${colors.orangeOverlay70};
  padding: ${sizes.petite}px ${sizes.base}px ${sizes.base}px ${sizes.base}px;
  border-radius: ${sizes.fine}px;
  backdrop-filter: blur(6px);
  box-shadow: 0px 6px 20px 6px ${colors.blackShadow};

  @media (min-width: ${breakpoints.textBox}px) {
    margin-top: ${sizes.base}px;
  }

  @media (min-width: ${breakpoints.desktopS}px) {
    margin: auto;
    background: none;
    padding: 0 ${sizes.twice}px ${sizes.petite}px ${sizes.petite}px;
    box-shadow: none;
    backdrop-filter: none;
  }
`;

const TitleImage = styled.img`
  margin-bottom: ${sizes.base}px;
`;

const Subline = styled.div`
  ${body};
  hyphenation: manual;
`;

const RedText = styled.span`
  color: ${colors.heat};
`;

const BlueText = styled.span`
  color: ${colors.rain};
`;

const ScrollDownContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: ${sizes.base}px;

  @media (min-width: ${breakpoints.desktopS}px) {
    padding-bottom: ${sizes.twice}px;
  }
`;

const ScrollDownText = styled.span`
  ${label};
`;

const arrowAnimation = keyframes`
 0% { transform: translateY(0) }
 50% { transform: translateY(10px) }
 100% { transform: translateY(0) }
`;

const ArrowContainer = styled.div`
  margin-top: -10px;
  animation: ${arrowAnimation} 1s infinite;
  animation-timing-function: linear;
`;

const HeroText = () => {
  return (
    <>
      <TitleContainer>
        <TextContainer>
          <span id="waypoint" data-selected="title"></span>
          <TitleImage src={titleText} width="90%" height="auto" />
          <Subline>
            An overview of <RedText>hot summers</RedText> and{" "}
            <BlueText>heavy rainfall</BlueText> events between 2000 and 2021 in
            Germany and how they are affected by climate change.
          </Subline>
        </TextContainer>
        <ScrollDownContainer>
          <ScrollDownText>Scroll down here</ScrollDownText>
          <ArrowContainer>
            <ArrowRight
              style={{
                width: "50px",
                height: "50px",
                transform: `rotate(90deg)`,
              }}
            />
          </ArrowContainer>
        </ScrollDownContainer>
      </TitleContainer>
      <Einleitung />
    </>
  );
};

export default HeroText;
