import React, { useState, useRef, useEffect } from "react";
import useMeasure from "react-use-measure";
import styled from "styled-components";
import CueArrow from "../../images/svg/cue-arrow-down.svg";
import { AxisX } from "./axis-x";
import { Steps } from "./steps";
import { scaleLinear } from "d3";
import {
  annotation,
  breakpoints,
  colors,
  cueContainerStyle,
  cueStyle,
  label,
  shadowFilter,
  sizes,
} from "../../models";

interface StepperProps {
  selectedData: any;
  handleSelectedData: any;
  setCategory: React.Dispatch<React.SetStateAction<string | undefined>>;
  setPlaying: React.Dispatch<React.SetStateAction<boolean>>;
  setOverlayButtonControl: React.Dispatch<React.SetStateAction<boolean>>;
  overlayOpen: boolean;
}

const StepperBox = styled.div<any>`
  width: 100%;
  max-width: 600px;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 55px;
  z-index: 5;
  display: ${(props) => (props.overlayOpen ? "none" : "block")};

  @media (min-width: ${breakpoints.desktopS}px) {
    display: block;
    bottom: 20px;
  }
`;

const StepperTooltip = styled.div<{ arrow: string }>`
  ${label}
  opacity: 0;
  padding: 5px 12px;
  background: ${colors.rain};
  border-radius: 5px;
  position: absolute;
  transform: translateX(-50%);
  color: ${colors.white};
  white-space: nowrap;
  filter: ${shadowFilter.tooltip};

  &:after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -2.5px;
    border-width: 5px;
    border-style: solid;
    border-color: ${(props) =>
      `${props.arrow} transparent transparent transparent`};
  }

  @media (min-width: ${breakpoints.desktopS}px) {
    opacity: 1;
  }
`;

const CueContainer = styled.div`
  ${cueContainerStyle}
  transform: translate(-50%, -80%);
  margin-left: -22px;
`;

const Cue = styled.div`
  ${annotation};
  ${cueStyle}
`;

const Stepper = ({
  selectedData,
  handleSelectedData,
  setCategory,
  setPlaying,
  setOverlayButtonControl,
  overlayOpen,
}: StepperProps) => {
  const [ref, bounds] = useMeasure();
  const [tooltipContent, setTooltipContent] = useState<string>("");
  const [tooltipPos, setTooltipPos] = useState<any>({ x: 0, y: 0 });
  const [cueVisible, setCueVisible] = useState<boolean>(true);
  const width = bounds.width;

  const createStepper = () => {
    const margins =
      bounds.width >= 600
        ? { top: 0, right: 17, bottom: 0, left: 17 }
        : { top: 0, right: 30, bottom: 0, left: 30 };
    const chartWidth = width - margins.left - margins.right;
    const chartHeight = 87;

    const scaleX = scaleLinear().domain([2000, 2021]).range([0, chartWidth]);

    return (
      <svg width={bounds.width} height={chartHeight}>
        <g
          transform={`translate(${margins.left}, 0)`}
          style={{ pointerEvents: "auto" }}
        >
          <AxisX scaleX={scaleX} chartWidth={chartWidth} />
          <Steps
            scaleX={scaleX}
            selectedData={selectedData}
            handleSelectedData={handleSelectedData}
            setCategory={setCategory}
            setPlaying={setPlaying}
            setOverlayButtonControl={setOverlayButtonControl}
            setTooltipContent={setTooltipContent}
            setTooltipPos={setTooltipPos}
            setCueVisible={setCueVisible}
          />
        </g>
      </svg>
    );
  };
  return (
    <StepperBox ref={ref} overlayOpen={overlayOpen}>
      <CueContainer
        style={{ display: !selectedData && cueVisible ? "flex" : "none" }}
      >
        <Cue>
          You want to explore the data by yourself? Choose an extreme weather
          event.
        </Cue>
        <CueArrow />
      </CueContainer>
      {width != 0 && createStepper()}

      <StepperTooltip
        style={{
          left: `${tooltipPos.x}px`,
          top: `${tooltipPos.y}px`,
          display: tooltipContent ? "block" : "none",
          background:
            tooltipContent.slice(0, 2) === "He" ? colors.heat : colors.rain,
        }}
        arrow={tooltipContent.slice(0, 2) === "He" ? colors.heat : colors.rain}
      >
        {tooltipContent}
      </StepperTooltip>
    </StepperBox>
  );
};

export default Stepper;
