import { BlockList } from "net";
import React, { useRef, useEffect } from "react";
import styled from "styled-components";
import Cross from "../../images/svg/cross.svg";
import { breakpoints, buttonStyle, colors } from "../../models";

interface VideoOverlayProps {
  videoOpen: boolean;
  setVideoOpen: React.Dispatch<React.SetStateAction<boolean>>;
  videoData: string;
}

const OverlayContainer = styled.div`
  background: ${colors.blackOverlay};
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 100;
  align-items: center;
  justify-content: center;
`;

const CrossContainer = styled.div`
  ${buttonStyle};
  border-radius: 50px;
  height: 32px;
  position: relative;
  transform: translate(-50%, -16px);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);

  @media (min-width: ${breakpoints.tabletL}px) {
    height: 52px;
    transform: translate(-50%, -26px);
  }
`;

const PlayerIFrame = styled.iframe`
  width: 224px;
  height: 126px;
  @media (min-width: ${breakpoints.tabletL}px) {
    width: 560px;
    height: 315px;
  }
`;

const CrossCircle = styled.svg`
  width: 32px;
  height: 32px;

  @media (min-width: ${breakpoints.tabletL}px) {
    width: 52px;
    height: 52px;
  }
`;

const VideoOverlay = ({
  videoOpen,
  setVideoOpen,
  videoData,
}: VideoOverlayProps) => {
  const handleClose = (e: any) => {
    setVideoOpen(false);
  };

  return (
    <OverlayContainer style={{ display: videoOpen ? "flex" : "none" }}>
      {videoData && (
        <div style={{ display: "flex", marginRight: "-26px" }}>
          {videoOpen && (
            <PlayerIFrame
              src={`${videoData}`}
              title="YouTube video player"
              frameBorder={0}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
              style={{ display: "block", margin: "auto" }}
            ></PlayerIFrame>
          )}
          <CrossContainer onClick={handleClose}>
            <CrossCircle viewBox="0 0 52 52">
              <g transform={"translate(1,1)"}>
                <Cross />
              </g>
            </CrossCircle>
          </CrossContainer>
        </div>
      )}
    </OverlayContainer>
  );
};

export default VideoOverlay;
