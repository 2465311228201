import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { useIntersection } from "../../hooks";
import { MainText } from "../../content";
import {
  colors,
  sizes,
  body,
  breakpoints,
  h3,
  ScreenHeight75,
  ScreenHeight25,
  shadowFilter,
} from "../../models";
import IntersectionHandle from "./intersection-handle";
import HeroText from "./hero";
import Footer from "./footer";

interface StoryProps {
  handleSelectedData: (selectedId: string) => void;
  selectedData: any;
  selectedEvents: any;
  overlayOpen: boolean;
  setOverlayOpen: React.Dispatch<React.SetStateAction<boolean>>;
  scrollySelectedId: string;
  setScrollySelectedId: React.Dispatch<React.SetStateAction<string>>;
  overlayButtonControl: boolean;
  setOverlayButtonControl: React.Dispatch<React.SetStateAction<boolean>>;
}

const StoryTextWrapper = styled.div`
  max-width: 440px;
  margin: auto;
`;

const TextContainer = styled.div`
  padding: ${sizes.base}px ${sizes.base}px 0 ${sizes.base}px;
  background: ${colors.orangeOverlay70};
  border-top: 1px solid ${colors.gridLight};
  border-bottom: 1px solid ${colors.gridLight};
  backdrop-filter: blur(6px);

  @media (min-width: ${breakpoints.textBox}px) {
    border: 1px solid ${colors.gridLight};
    border-radius: ${sizes.fine}px;
  }

  @media (min-width: ${breakpoints.desktopS}px) {
    border: none;
    backdrop-filter: none;
    padding: 0 ${sizes.twice}px 0 ${sizes.petite}px;
  }
`;

const StepHeadline = styled.div`
  ${h3};
`;

const BodyText = styled.div`
  ${body};
  p {
    margin: 0;
    padding: ${sizes.fine}px 0 ${sizes.petite}px 0;
  }
`;

const Story = ({
  handleSelectedData,
  selectedData,
  selectedEvents,
  overlayOpen,
  setOverlayOpen,
  scrollySelectedId,
  setScrollySelectedId,
  overlayButtonControl,
  setOverlayButtonControl,
}: StoryProps) => {
  const scrollerContainerRef = useRef(null);
  const storyIds = [
    "Hitzesommer2003",
    "Hochwasser2013",
    "Hitzesommer2018",
    "Hitzesommer2019",
    "Sturzflut2021",
  ];

  useEffect(() => {
    if (!selectedData) return;
    const currentId = `${selectedData.Klimasignal}${selectedData.Zeitraum}`;
    const elementToScrollTo: any = document.getElementById(`${currentId}Text`);

    if (
      storyIds.includes(currentId) &&
      (scrollySelectedId != currentId || !scrollySelectedId)
    ) {
      setOverlayOpen(true);
      elementToScrollTo.scrollIntoView();
    } else if (!storyIds.includes(currentId) && overlayOpen) {
      setOverlayOpen(false);
    } else if (
      storyIds.includes(currentId) &&
      !overlayOpen &&
      !overlayButtonControl
    ) {
      setOverlayOpen(true);
      elementToScrollTo.scrollIntoView();
    }
  }, [selectedData, overlayOpen]);

  useEffect(() => {
    if (!scrollySelectedId) return;
    handleSelectedData(scrollySelectedId);
  }, [scrollySelectedId]);

  return (
    <StoryTextWrapper ref={scrollerContainerRef}>
      <HeroText />
      {MainText &&
        MainText.map((entry: any) => {
          return (
            <div key={`${entry.id}-story`}>
              <ScreenHeight25 id={entry.id} />
              <TextContainer>
                <StepHeadline
                  id="waypoint"
                  data-selected={entry.selectedId}
                  data-playing={entry.playing}
                  data-videos={entry.videos}
                >
                  {entry.headline}
                </StepHeadline>
                <BodyText>{entry.text}</BodyText>
                <span
                  id="waypoint"
                  data-selected={entry.selectedId}
                  data-playing={entry.playing}
                  data-videos={entry.videos}
                >
                  {" "}
                </span>
              </TextContainer>
              <ScreenHeight75 />
            </div>
          );
        })}
      <Footer />
      <IntersectionHandle
        scrollerContainerRef={scrollerContainerRef}
        setScrollySelectedId={setScrollySelectedId}
        setOverlayButtonControl={setOverlayButtonControl}
      />
    </StoryTextWrapper>
  );
};

export default Story;
