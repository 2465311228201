import React from "react";
import styled from "styled-components";
import { breakpoints, colors, fonts, fontSizes } from "../models";

const HighlightedBody = styled.b`
  font-weight: 600;
  @media (min-width: ${breakpoints.desktopS}px) {
    font-weight: 600;
  }
`;

const LinkNumber = styled.a`
  font-size: ${fontSizes.micro};
  font-weight: ${fonts.weightBold};
  text-decoration: none;
  color: ${colors.black};
`;

export const MainText = [
  {
    selectedId: "Hitzesommer2003",
    id: "Hitzesommer2003Text",
    headline: "Alarming record",
    date: "",
    videos: false,
    playing: true,
    text: (
      <>
        <p>
          The three hottest summers in recorded history all occured after the
          year 2000.
          <LinkNumber
            href="https://www.dwd.de/DE/presse/pressemitteilungen/DE/2020/20200702_dach_news.html"
            target={"_blank"}
          >
            <sup>[1]</sup>
          </LinkNumber>{" "}
          One of them was the heatwave summer of 2003. In that year alone, 9,355
          people died in Germany as a result of the heat. Especially in weakened
          persons, high temperatures can cause lung failure and heart attacks.
        </p>
        <p>
          On the map you can see comparatively mild temperatures at the
          beginning of summer on 01.06.2003. The hottest period was in early
          August.{" "}
          <HighlightedBody>
            The previous German heat record was broken on 08.08.2013 with
            40.3°C.
          </HighlightedBody>
          <LinkNumber
            href="https://de.wikipedia.org/wiki/Hitzewelle_in_Europa_2003"
            target={"_blank"}
          >
            <sup>[2]</sup>
          </LinkNumber>{" "}
          Use the slider to view the temperatures on this day.
        </p>
      </>
    ),
  },
  {
    selectedId: "Hochwasser2013",
    id: "Hochwasser2013Text",
    headline: "Germany under water",
    date: "",
    videos: true,
    playing: false,
    text: (
      <>
        <p>
          Besides heat waves, heavy rainfall events are also becoming more
          likely due to climate change. At the end of May and beginning of June
          2013, days of rain caused flooding in Central Europe.{" "}
          <HighlightedBody>
            Large parts of Germany were affected, especially areas along the
            Danube, Saale, Elbe and other rivers.
          </HighlightedBody>
          <LinkNumber
            href="https://www.eskp.de/naturgefahren/das-juni-hochwasser-2013-in-deutschland-935306/"
            target={"_blank"}
          >
            <sup>[3]</sup>
          </LinkNumber>
        </p>
        <p>
          Environmentalists name dike construction, river straightening, soil
          sealing, and the loss of natural floodplains as causes of more severe
          flooding.
          <LinkNumber
            href="https://www.bpb.de/kurz-knapp/hintergrund-aktuell/163064/hochwasser-in-deutschland-2013/"
            target={"_blank"}
          >
            <sup>[4]</sup>
          </LinkNumber>{" "}
        </p>
      </>
    ),
  },
  {
    selectedId: "Hitzesommer2018",
    id: "Hitzesommer2018Text",
    headline: "It gets even hotter",
    date: "",
    videos: true,
    playing: false,
    text: (
      <>
        <p>
          The months from April to July 2018 were hotter than at any time since
          weather records began. Combined with low rainfall, the high
          temperatures resulted in{" "}
          <HighlightedBody>
            89% of land in Germany being affected by drought by mid-August.
          </HighlightedBody>
          <LinkNumber
            href="https://www.scinexx.de/news/geowissen/hitzesommer-2018-brach-rekorde/"
            target={"_blank"}
          >
            <sup>[5]</sup>
          </LinkNumber>{" "}
        </p>
        <p>
          At the same time the first school strikes begin in Germany on Fridays.
          Inspired by the then 15-year-old Greta Thunberg, they serve as a form
          of protest against a flawed climate policy.
          <LinkNumber
            href="https://www.klimareporter.de/erdsystem/deutschland-kein-jahr-war-heisser-als-2018"
            target={"_blank"}
          >
            <sup>[6]</sup>
          </LinkNumber>{" "}
        </p>
      </>
    ),
  },
  {
    selectedId: "Hitzesommer2019",
    id: "Hitzesommer2019Text",
    headline: "New peaks",
    date: "",
    videos: true,
    playing: false,
    text: (
      <>
        <p>
          On July 25, 2019, the highest temperature ever measured in Germany,
          40.3°C, will be exceeded at several stations.{" "}
          <HighlightedBody>
            In Lingen in Lower Saxony, 42.6°C is measured on this day.
          </HighlightedBody>{" "}
          The second half of July brought an enormous heat wave that hit western
          Germany.
          <LinkNumber
            href="https://www.wetter.de/cms/hitzewelle-juli-2019-als-die-hitze-deutschland-fest-im-griff-hatte-4577741.html"
            target={"_blank"}
          >
            <sup>[7]</sup>
          </LinkNumber>{" "}
          Like the year before, there were severe droughts, also because the
          amount of precipitation fell well short of the target value.
          <LinkNumber
            href="https://www.nationalgeographic.de/umwelt/2019/09/rekordhitze-und-duerre-der-sommer-2019-war-extrem"
            target={"_blank"}
          >
            <sup>[8]</sup>
          </LinkNumber>{" "}
        </p>
      </>
    ),
  },
  {
    selectedId: "Sturzflut2021",
    id: "Sturzflut2021Text",
    headline: "Ahrtal",
    date: "",
    videos: true,
    playing: false,
    text: (
      <>
        <p>
          A catastrophe that most people in Germany still remember were the
          flash floods in Ahrtal. Heavy rainfall on July 14, 2021, and July 15,
          2021, caused the Ahr River to reach a record level of 5 meters.{" "}
          <HighlightedBody>
            A gravel pit in Erftstadt Blessem collapsed as a result of the
            flooding of the Erft River, sweeping away numerous houses.
          </HighlightedBody>
          <LinkNumber
            href="https://www.mdr.de/brisant/hochwasser-ahrtal-100.html"
            target={"_blank"}
          >
            <sup>[9]</sup>
          </LinkNumber>{" "}
        </p>
        <p>
          The total damage caused is estimated to over €40 million. 183 people
          died as a result of the disaster abd numerous villages in the Ahr
          valley were completely destroyed.
        </p>
      </>
    ),
  },
  {
    selectedId: "fazit",
    id: "FazitText",
    headline: "Conclusion",
    date: "",
    videos: true,
    playing: false,
    text: (
      <>
        <p>
          If climate change continues as before, it will cause more intense
          heatwaves and more severe flooding. On the one hand, this means
          enormous costs due to the destruction of infrastructure and the loss
          of harvests. On the other hand, extreme weather events repeatedly
          claim many lives, especially among vulnerable groups. In addition to
          better handling in disaster management, strong measures must be taken
          to minimize the rise in temperature.
        </p>
        <p>
          Use the timeline below the map to view more events and related video
          reports.
        </p>
      </>
    ),
  },
];
