import React, { useRef, useEffect } from "react";
import { select, axisTop, timeFormat, timeDay } from "d3";
import { colors, fonts, fontSizes } from "../../models";

interface SliderAxisProps {
  xScale: any;
  dayRange: number;
}

export const SliderAxis = ({ xScale, dayRange }: SliderAxisProps) => {
  const ref = useRef<any>();

  useEffect(() => {
    const xAxisG = select(ref.current);

    const tickTextSelector = (days: number, d: any, i: number) => {
      if (days >= 61) {
        return d.getDate() === 1 ? timeFormat("%d/%m")(d) : "";
      } else if (days >= 10) {
        return d.getDay() === 1 ? timeFormat("%d/%m")(d) : "";
      } else {
        return i % 2 == 0 ? "" : timeFormat("%d/%m")(d);
      }
    };

    const xAxis = axisTop(xScale)
      .ticks(timeDay)
      .tickSizeOuter(0)
      .tickFormat((d: any, i: number) => {
        if (i != 0) {
          return tickTextSelector(dayRange, d, i);
        }
      });

    xAxisG
      .call(xAxis)
      .call((g) => g.select(".domain").remove())
      .call((g) =>
        g
          .selectAll(".tick")
          .selectAll("line")
          .attr("y1", -2)
          .attr("y2", -7)
          .style("stroke", colors.white)
          .style("stroke-width", "2.5px")
          .style("stroke-linecap", "round")
      )
      .call((g) =>
        g
          .selectAll(".tick")
          .append("line")
          .attr("class", "background-ticks")
          .attr("y1", -2)
          .attr("y2", -7)
          .style("stroke", colors.gridLight)
      )
      .call((g) =>
        g
          .selectAll("text")
          .attr("color", colors.grid)
          .attr("dy", -2)
          .style("font-family", fonts.base)
          .style("font-size", fontSizes.small)
          .style("stroke-width", "2.5px")
          .style("paint-order", "stroke")
          .style("stroke", colors.white)
          .style("stroke-opacity", 0.8)
      );
  }, [xScale]);

  return <g ref={ref} transform="translate(0,27)" />;
};
