import React, { useState, useRef, useEffect, useCallback } from "react";
import ReactSlider from "react-slider";
import styled from "styled-components";
import { buttonStyle, colors } from "../../models";
import Play from "../../images/svg/play.svg";
import Pause from "../../images/svg/pause.svg";
import "./slider.css";

interface PlayButtonProps {
  sliderValue: number;
  handleSliderValue: any;
  playing: boolean;
  setPlaying: React.Dispatch<React.SetStateAction<boolean>>;
  count: number;
  setCount: React.Dispatch<React.SetStateAction<number>>;
}

const PlayButtonContainer = styled.div``;

const Button = styled.div`
  ${buttonStyle};
  border-radius: 50px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PlayButton = ({
  sliderValue,
  handleSliderValue,
  playing,
  setPlaying,
  count,
  setCount,
}: PlayButtonProps) => {
  const [buttonIcon, setButtonIcon] = useState<any>(
    <Play style={{ width: "18px", height: "18px" }} />
  );

  useEffect(() => {
    if (playing) handleSliderValue(Math.round(count));
  }, [Math.round(count)]);

  useEffect(() => {
    if (!playing && count != sliderValue) setCount(sliderValue);
  }, [sliderValue]);

  const requestRef = React.useRef<any>();
  const previousTimeRef = React.useRef<any>();

  const animate = (time: any) => {
    if (previousTimeRef.current != undefined) {
      const deltaTime = time - previousTimeRef.current;
      setCount((prevCount: number) => prevCount + deltaTime * 0.0007);
    }
    previousTimeRef.current = time;
    requestRef.current = requestAnimationFrame(animate);
  };

  useEffect(() => {
    if (playing) {
      requestRef.current = requestAnimationFrame(animate);
      setButtonIcon(<Pause style={{ width: "18px", height: "18px" }} />);
    } else {
      cancelAnimationFrame(requestRef.current);
      previousTimeRef.current = undefined;
      setButtonIcon(<Play style={{ width: "18px", height: "18px" }} />);
    }
    return () => cancelAnimationFrame(requestRef.current);
  }, [playing]);

  const handleButtonClick = () => {
    if (!playing) {
      setPlaying(true);
    } else {
      setPlaying(false);
    }
  };

  return (
    <>
      <PlayButtonContainer onClick={handleButtonClick}>
        <Button>{buttonIcon}</Button>
      </PlayButtonContainer>
    </>
  );
};
