import { createFontSizeScale } from "../util";
import "@fontsource/roboto-flex/variable-full.css";

export enum sizes {
  hair = 1,
  dblhair = 2,
  bristle = 3,
  micro = 4,
  tiny = 6,
  thin = 8,
  fine = 10,
  little = 14,
  base = 16,
  small = 18,
  petite = 20,
  junior = 24,
  compact = 28,
  twice = 32,
  medium = 36,
  hefty = 40,
  big = 48,
  large = 56,
  wide = 72,
  huge = 90,
  mediumhuge = 154,
  extremehuge = 200,
}

export const colors = {
  transparent: "rgba(0, 0, 0, 0)",
  orangeBackground: "#FFFEFA",
  orangeOverlay70: "rgba(255, 254, 250, 0.7)",
  white: "#FFF",
  white80: "rgba(255, 255, 255, 0.8);",
  black: "#202020",
  blackOverlay: "rgba(32, 32, 32, 0.3)",
  blackShadow: "rgba(32, 32, 32, 0.2)",
  buttonHover: "#F9F9F9",
  grid: "rgba(128, 128, 128, 0.8)",
  gridLight: "rgba(128, 128, 128, 0.3)",
  heat: "#FF3434",
  heatHover: "#E61717",
  heat40Deselect: "rgba(255, 52, 52, 0.4)",
  heat20: "rgba(255, 52, 52, 0.2)",
  rain: "#3448FF",
  rainHover: "#172CE6",
  rain40Deselect: "rgba(52, 72, 255, 0.4)",
  rain20: "rgba(52, 72, 255, 0.2)",
};

export const fonts = {
  base: "'Roboto FlexVariable', sans-serif",
  weightRegular: "400",
  weightBold: "700",
  weightBlack: "900",
  fontStretchExtended: "150%",
};

const baseFontSize = 16;
const { above, below } = createFontSizeScale(baseFontSize, 1.25, {
  above: 6,
  below: 3,
});
const [small, micro, macro] = below.map((s) => `${s}px`);
const [midSize, big, large, huge, gigantic, mega] = above.map((s) => `${s}px`);

/*
  {
    macro: "8.192px",
    micro: "10.24px",
    small: "12.8px",
    base: "16px",
    midSize: "20px",
    big: "25px",
    large: "31.25px",
    huge: "39.0625px",
    gigantic: "48.828125px"
    mega: "61.04px"
  }
*/
export const fontSizes = {
  macro,
  micro,
  small,
  base: `${baseFontSize}px`,
  midSize,
  big,
  large,
  huge,
  gigantic,
  mega,
};

export const lineHeights = {
  small: `1.2em`,
  body: `1.4em`,
};

export const strokes = {
  thin: 0.75,
  base: 1,
  big: 1.5,
};

export const shadowFilter = {
  tooltip: "drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.18))",
  cue: "drop-shadow(0px 2px 15px rgba(0, 0, 0, 0.18))",
  slider: "drop-shadow(0px 2px 5px rgba(0, 0, 0, 0.5))",
};

export const circleRadiusScale = {
  domainHeat: [0, 24.9, 25, 40],
  domainRain: [0, 1, 150],
  rangeHeat: [0, 0, 2, 20],
  rangeRain: [0, 2, 20],
};

export const breakpoints = {
  smartphone: 0,
  textBox: 440,
  tabletL: 768,
  desktopS: 1200,
  desktopL: 1440,
  desktopXL: 1920,
};

export const gutterWidths = {
  smartphone: 16,
  tabletL: 24,
  desktopS: 24,
  desktopL: 32,
  desktopXL: 32,
};

export const gridMargin = {
  smartphone: 16,
  tabletL: 24,
  desktopS: 32,
  desktopL: 56,
  desktopXL: 72,
};
