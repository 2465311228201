import React, { useState, useEffect } from "react";
import { select, axisBottom, scaleLinear } from "d3";
import {
  breakpoints,
  colors,
  fatLabel,
  fonts,
  fontSizes,
  h2,
  label,
  sizes,
} from "../../models";
import styled from "styled-components";
import { Icon } from "../stepper/icon";

interface TitleProps {
  selectedData: any;
}

const TitleContainer = styled.div`
  padding: 5px 5px 5px 5px;
  border: 1px solid ${colors.gridLight};
  border-radius: 5px;
  background-color: ${colors.orangeOverlay70};
  backdrop-filter: blur(2px);
  margin-bottom: ${sizes.tiny}px;
  box-shadow: 0px 4px 20px ${colors.blackShadow};

  @media (min-width: ${breakpoints.tabletL}px) {
    padding: 2px 10px 5px 10px;
  }

  @media (min-width: ${breakpoints.desktopS}px) {
    box-shadow: none;
  }
`;

const IconContainerSvg = styled.svg`
  @media (max-width: 359px) {
    display: none;
  }
`;

const Headline = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
`;

const HeadlineText = styled.span`
  ${h2};
  padding-left: ${sizes.fine}px;
  @media (max-width: 359px) {
    padding-left: 2px;
  }
`;

const Subline = styled.div`
  display: flex;
  gap: ${sizes.petite}px;
  padding-left: ${sizes.hefty}px;

  @media (max-width: 359px) {
    padding-left: 3px;
  }
`;

const LabelText = styled.span`
  ${label}
`;

const FatLabelText = styled.span`
  ${fatLabel}
`;

export const Title = ({ selectedData }: TitleProps) => {
  return (
    <TitleContainer>
      <Headline>
        <IconContainerSvg width={30} height={30}>
          <circle
            cx={15}
            cy={15}
            r={15}
            fill={
              selectedData && selectedData.Klimasignal === "Hitzesommer"
                ? colors.heat
                : colors.rain
            }
          />
          <g transform={`translate(${15 - 18 / 2},${15 - 19 / 2})`}>
            <Icon
              type={"Hitzesommer"}
              selected={true}
              visited={false}
              opacityVisited={1}
            />
          </g>
        </IconContainerSvg>
        <HeadlineText
          style={{
            color: `${
              selectedData && selectedData.Klimasignal === "Hitzesommer"
                ? colors.heat
                : colors.rain
            }`,
          }}
        >
          {selectedData
            ? `${selectedData.Titelname} ${selectedData.Zeitraum}`
            : ""}
        </HeadlineText>
      </Headline>
      <Subline
        style={{
          color: `${
            selectedData && selectedData.Klimasignal === "Hitzesommer"
              ? colors.heat
              : colors.rain
          }`,
        }}
      >
        <div>
          <LabelText>Damage: </LabelText>
          <FatLabelText>
            {selectedData
              ? `${selectedData["Gesamtschaden_komplett"]} M €`
              : ""}
          </FatLabelText>
        </div>
        <div>
          <LabelText>Deaths: </LabelText>
          <FatLabelText>
            {selectedData ? `${selectedData["Todesfälle"]}` : ""}
          </FatLabelText>
        </div>
      </Subline>
    </TitleContainer>
  );
};
