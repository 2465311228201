import React, { useRef, useEffect, useState } from "react";
import eventData from "../../data/event-data.json";
import mapboxgl from "mapbox-gl";
import VideoMarker from "./video-marker";
import styled from "styled-components";
import { breakpoints } from "../../models";

interface VideoEventsProps {
  map: any;
  selectedEvents: any;
  setVideoOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setVideoData: React.Dispatch<React.SetStateAction<string>>;
  overlayOpen: boolean;
  width: number;
}

const VideoEvents = ({
  map,
  selectedEvents,
  setVideoOpen,
  setVideoData,
  overlayOpen,
  width,
}: VideoEventsProps) => {
  const createEvents = () => {
    const [cueVisible, setCueVisible] = useState<boolean>(true);

    console.log(overlayOpen);
    return (
      <>
        {Object.keys(eventData[0]).map((year: string) => {
          const events: any = eventData[0];
          const currentObject = events[year];
          const currentLinks = currentObject.links;
          return Object.keys(currentLinks).map((key, index) => {
            return (
              <VideoMarker
                key={`${year}-${index}-video-marker`}
                name={key}
                index={index}
                map={map}
                linkObject={currentLinks[key]}
                show={currentObject === selectedEvents ? true : false}
                setVideoOpen={setVideoOpen}
                setVideoData={setVideoData}
                cueVisible={cueVisible}
                setCueVisible={setCueVisible}
                overlayOpen={overlayOpen}
                width={width}
              />
            );
          });
        })}
      </>
    );
  };
  return <div>{map && createEvents()}</div>;
};

export default VideoEvents;
