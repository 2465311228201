import React, { useState, useEffect, useRef } from "react";
import { useIntersection } from "../../hooks";

interface IntersectionHandleProps {
  scrollerContainerRef: any;

  setScrollySelectedId: React.Dispatch<React.SetStateAction<string>>;
  setOverlayButtonControl: React.Dispatch<React.SetStateAction<boolean>>;
}

const IntersectionHandle = ({
  scrollerContainerRef,

  setScrollySelectedId,
  setOverlayButtonControl,
}: IntersectionHandleProps) => {
  useIntersection(
    scrollerContainerRef,
    "#waypoint",
    (entry: any) => {
      if (entry.intersectionRatio === 1) {
        const selectedId = entry.target.dataset.selected;
        // const playing = entry.target.dataset.playing;
        // const videos = entry.target.dataset.videos;
        // // setGlobalCoordinates(coordinateArrayOfFloat);
        // setTextData({ playing: playing, videos: videos });
        setScrollySelectedId(selectedId);
        setOverlayButtonControl(false);
      }
    },
    { threshold: 1, rootMargin: "0px" }
  );

  return null;
};

export default IntersectionHandle;
