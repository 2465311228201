import React, { useState, useEffect, useRef } from "react";
import MapboxMapStandard from "./map/mapbox_map";
import styled, { keyframes } from "styled-components";
import phenomena from "../data/phenomena.json";
import eventData from "../data/event-data.json";

import { Stepper } from "./stepper";
import { ChapterTitle } from "./chapter-title";
import { StoryOverlay } from "./story";
import { Legend } from "./legend";
import { VideoOverlay } from "./video-overlay";
import Clouds from "./clouds";
import { breakpoints } from "../models";

interface VisualizationProps {}

const VisContainer = styled.div`
  display: flex;
  height: 100dvh;
  max-height: -webkit-fill-available;
`;

const GraphicContainer = styled.div`
  width: 100%;
  position: relative;
  pointer-events: none;

  @media (min-width: ${breakpoints.desktopS}px) {
    z-index: 2;
  }
`;

const Visualization = () => {
  const visContainer = useRef<any>(null);
  const [windowWidth, setWindowWidth] = useState<any>(null);
  const [date, setDate] = useState<Date>(new Date(2000, 0));
  const [category, setCategory] = useState<string | undefined>("");
  const [selectedData, setSelectedData] = useState<any>(undefined);
  const [selectedEvents, setSelectedEvents] = useState<any>(undefined);
  const [overlayOpen, setOverlayOpen] = useState<boolean>(true);
  const [playing, setPlaying] = useState<boolean>(false);
  const [overlayButtonControl, setOverlayButtonControl] =
    useState<boolean>(false);
  const [overlayTriggeredByButton, setOverlayTriggeredByButton] =
    useState<boolean>(false); // checks if overlay close/open was triggered by button
  const [videoOpen, setVideoOpen] = useState<boolean>(false);
  const [videoData, setVideoData] = useState<string>("");
  const [isFazit, setIsFazit] = useState<boolean>(false);
  const [zoomMultiplicator, setZoomMultiplicator] = useState<number>(0);
  const [zoom, setZoom] = useState(0);

  useEffect(() => {
    if (!visContainer) return;
    const windowWidth = visContainer.current.offsetWidth;
    if (windowWidth != 0) setWindowWidth(windowWidth);
  }, [visContainer]);

  const handleSelectedData = (selectedId: string) => {
    const eventObject: any = eventData[0];
    if (selectedId === "title") {
      setSelectedData(undefined);
      setCategory(undefined);
      setSelectedEvents(eventObject["title"]);
      setIsFazit(false);
    } else if (selectedId === "fazit") {
      setSelectedData(undefined);
      setCategory(undefined);
      setSelectedEvents(eventObject["fazit"]);
      setDate(new Date(eventObject["fazit"]["date"]));
      setIsFazit(true);
    } else {
      const filteredData = phenomena.filter(
        (d) => `${d.Klimasignal}${d.Zeitraum}` === selectedId
      )["0"];

      const selectedCategory =
        filteredData.Klimasignal === "Hitzesommer" ? "temperature" : "rain";

      const filteredEvents = eventObject[`${filteredData.Zeitraum}`];
      const date = new Date(filteredEvents.date);

      setSelectedData(filteredData);
      setSelectedEvents(filteredEvents);
      setDate(date);
      setCategory(selectedCategory);
      setIsFazit(false);
    }
  };

  return (
    <VisContainer ref={visContainer}>
      <GraphicContainer>
        <ChapterTitle
          selectedData={selectedData}
          setDate={setDate}
          playing={playing}
          setPlaying={setPlaying}
          selectedEvents={selectedEvents}
          isFazit={isFazit}
        />
        <Stepper
          selectedData={selectedData}
          handleSelectedData={handleSelectedData}
          setCategory={setCategory}
          setPlaying={setPlaying}
          setOverlayButtonControl={setOverlayButtonControl}
          overlayOpen={overlayOpen}
        />
        <Legend
          category={category}
          selectedData={selectedData}
          windowWidth={windowWidth}
        />
      </GraphicContainer>
      <StoryOverlay
        overlayOpen={overlayOpen}
        setOverlayOpen={setOverlayOpen}
        handleSelectedData={handleSelectedData}
        selectedData={selectedData}
        selectedEvents={selectedEvents}
        overlayButtonControl={overlayButtonControl}
        setOverlayButtonControl={setOverlayButtonControl}
        setOverlayTriggeredByButton={setOverlayTriggeredByButton}
      />
      <MapboxMapStandard
        date={date}
        category={category}
        overlayOpen={overlayOpen}
        selectedEvents={selectedEvents}
        setVideoOpen={setVideoOpen}
        setVideoData={setVideoData}
        overlayTriggeredByButton={overlayTriggeredByButton}
        setOverlayTriggeredByButton={setOverlayTriggeredByButton}
        zoom={zoom}
        setZoom={setZoom}
        zoomMultiplicator={zoomMultiplicator}
        setZoomMultiplicator={setZoomMultiplicator}
      />
      <Clouds
        selectedEvents={selectedEvents}
        zoom={zoom}
        zoomMultiplicator={zoomMultiplicator}
      />
      <VideoOverlay
        videoOpen={videoOpen}
        setVideoOpen={setVideoOpen}
        videoData={videoData}
      />
    </VisContainer>
  );
};

export default Visualization;
