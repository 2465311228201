import React, { useRef, useEffect } from "react";
import styled from "styled-components";
import { breakpoints, colors, fonts, fontSizes } from "../../models";
import { Title } from "./title";
import { Process } from "./process";

interface ChapterTitleProps {
  selectedData: undefined | Object;
  setDate: any;
  playing: boolean;
  setPlaying: React.Dispatch<React.SetStateAction<boolean>>;
  selectedEvents: any;
  isFazit: boolean;
}

const ChapterContainer = styled.div`
  position: absolute;
  left: 16px;
  right: 16px;
  top: 10px;
  pointer-events: auto;
  z-index: 4;

  @media (min-width: ${breakpoints.tabletL}px) {
    max-width: 421px;
    left: 32px;
    right: auto;
    top: 20px;
  }
`;

const ChapterTitle = ({
  selectedData,
  setDate,
  playing,
  setPlaying,
  selectedEvents,
  isFazit,
}: ChapterTitleProps) => {
  return (
    <ChapterContainer
      style={{
        display: selectedData ? "block" : "none",
      }}
    >
      <Title selectedData={selectedData} />
      <Process
        selectedData={selectedData}
        setDate={setDate}
        playing={playing}
        setPlaying={setPlaying}
        selectedEvents={selectedEvents}
        isFazit={isFazit}
      />
    </ChapterContainer>
  );
};

export default ChapterTitle;
