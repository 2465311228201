import * as React from "react";
import favicon from "../images/icon.png";
import ogImage from "../images/ogImage.png";
import "./general-styles.css";
import { Helmet } from "react-helmet";
import { Visualization } from "../components";

const IndexPage: React.FC = () => {
  return (
    <>
      <Helmet>
        <link rel="icon" type="image/png" href={favicon} sizes="32x32" />
        <meta property="og:title" content="Wetter" />
        <meta property="og:image" content={ogImage} />
      </Helmet>
      <main>
        <Visualization />
      </main>
    </>
  );
};

export default IndexPage;
