import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { breakpoints, buttonStyle, colors, sizes } from "../../models";
import OverlayButton from "./overlay-button";
import Story from "./story";

interface StoryOverlayProps {
  overlayOpen: boolean;
  setOverlayOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleSelectedData: (selectedId: string) => void;
  selectedData: any;
  selectedEvents: any;
  overlayButtonControl: boolean;
  setOverlayButtonControl: React.Dispatch<React.SetStateAction<boolean>>;
  setOverlayTriggeredByButton: React.Dispatch<React.SetStateAction<boolean>>;
}

const StoryContainer = styled.div`
  height: 100vh;
  overflow: scroll;
  overflow-x: hidden;
  background-color: transparent;
  border-left: 1px solid ${colors.black};
  z-index: 3;
  position: absolute;
  width: 100%;

  @media (min-width: ${breakpoints.desktopS}px) {
    position: relative;
    background-color: ${colors.orangeBackground};
    padding: 0;
    max-width: 440px;
    flex: 0 0 440px;
  }
`;

const StoryOverlay = ({
  overlayOpen,
  setOverlayOpen,
  handleSelectedData,
  selectedData,
  selectedEvents,
  overlayButtonControl,
  setOverlayButtonControl,
  setOverlayTriggeredByButton,
}: StoryOverlayProps) => {
  const [width, setWidth] = useState<string>("440px");
  const [scrollySelectedId, setScrollySelectedId] = useState<string>("");

  useEffect(() => {
    overlayOpen ? setWidth("440px") : setWidth("0");
  }, [overlayOpen]);

  return (
    <>
      <OverlayButton
        overlayOpen={overlayOpen}
        setOverlayOpen={setOverlayOpen}
        selectedEvents={selectedEvents}
        width={width}
        scrollySelectedId={scrollySelectedId}
        handleSelectedData={handleSelectedData}
        setOverlayButtonControl={setOverlayButtonControl}
        setOverlayTriggeredByButton={setOverlayTriggeredByButton}
      />
      <StoryContainer
        style={{
          display: `${overlayOpen ? "block" : "none"}`,
        }}
      >
        <Story
          handleSelectedData={handleSelectedData}
          selectedData={selectedData}
          selectedEvents={selectedEvents}
          overlayOpen={overlayOpen}
          setOverlayOpen={setOverlayOpen}
          scrollySelectedId={scrollySelectedId}
          setScrollySelectedId={setScrollySelectedId}
          overlayButtonControl={overlayButtonControl}
          setOverlayButtonControl={setOverlayButtonControl}
        />
      </StoryContainer>
    </>
  );
};

export default StoryOverlay;
