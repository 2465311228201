import React, { useRef, useEffect } from "react";
import { scaleLinear, scaleOrdinal, scaleSqrt } from "d3";
import useMeasure from "react-use-measure";
import styled from "styled-components";
import {
  breakpoints,
  circleRadiusScale,
  colors,
  fonts,
  fontSizes,
  label,
  sizes,
} from "../../models";

interface ChapterTitleProps {
  category: string | undefined;
  selectedData: any;
  windowWidth: any;
}

const LegendContainer = styled.div`
  position: absolute;
  right: ${sizes.base}px;
  bottom: 160px;
  border: 1px solid ${colors.gridLight};
  border-radius: 5px;
  padding: 5px ${sizes.fine}px;
  background-color: ${colors.orangeOverlay70};
  backdrop-filter: blur(2px);
  z-index: 3;

  @media (min-width: ${breakpoints.desktopS}px) {
    right: ${sizes.twice}px;
    bottom: 115px;
  }
`;

const LegendLabel = styled.div`
  ${label}
  text-align:center;
  color: ${colors.grid};
`;

const Legend = ({ category, selectedData, windowWidth }: ChapterTitleProps) => {
  const createLegendSvg = () => {
    const isTemperature = category === "temperature";
    const chartWidth = isTemperature
      ? windowWidth >= breakpoints.desktopS
        ? 132
        : 80
      : windowWidth >= breakpoints.desktopS
      ? 139
      : 89;
    const chartHeight = 58;
    const values = isTemperature
      ? windowWidth >= breakpoints.desktopS
        ? [25, 32, 40]
        : [25, 40]
      : windowWidth >= breakpoints.desktopS
      ? [1, 75, 150]
      : [1, 150];
    const color = isTemperature ? colors.heat : colors.rain;
    const scaleCircleRadius = scaleSqrt()
      .domain(
        isTemperature
          ? circleRadiusScale.domainHeat
          : circleRadiusScale.domainRain
      )
      .range(
        isTemperature
          ? circleRadiusScale.rangeHeat
          : circleRadiusScale.rangeRain
      );
    const scaleX = scaleLinear()
      .domain([0, 1, 2])
      .range(
        isTemperature
          ? [14, 58, chartWidth - scaleCircleRadius(values[values.length - 1])]
          : [
              19.5,
              63.5,
              chartWidth - scaleCircleRadius(values[values.length - 1]) - 2,
            ]
      );

    return (
      <>
        <svg width={chartWidth} height={chartHeight}>
          <g>
            {values.map((value: number, index: number) => {
              return (
                <g key={`${index}-legend-element`}>
                  <circle
                    cx={scaleX(index)}
                    cy={chartHeight - scaleCircleRadius(value)}
                    r={scaleCircleRadius(value)}
                    fill={color}
                    fillOpacity={0.2}
                  />
                  <text
                    x={scaleX(index)}
                    y={chartHeight - scaleCircleRadius(value) * 2}
                    fontFamily={fonts.base}
                    fontSize={fontSizes.small}
                    fill={color}
                    textAnchor="middle"
                    dy="-4px"
                  >
                    {`${value}${isTemperature ? "°C" : " mm"}`}
                  </text>
                </g>
              );
            })}
          </g>
        </svg>
        <LegendLabel>
          {isTemperature ? (
            windowWidth >= breakpoints.desktopS ? (
              <>Max. temperature</>
            ) : (
              <>
                Max.
                <br />
                temperature
              </>
            )
          ) : windowWidth >= breakpoints.desktopS ? (
            <>Precipitation height</>
          ) : (
            <>
              Precipitation
              <br />
              height
            </>
          )}
        </LegendLabel>
      </>
    );
  };
  return (
    <LegendContainer style={{ display: selectedData ? "block" : "none" }}>
      {createLegendSvg()}
    </LegendContainer>
  );
};

export default Legend;
