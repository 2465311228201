import React, { useRef, useEffect, useState } from "react";
import useMeasure from "react-use-measure";
import temperatureHist from "../../data/high-temperatur-ohne-limit-hist.json";
import rainHist from "../../data/niederschlag-ohne-limit-hist.json";
import * as d3 from "d3";
import { colors } from "../../models";
import { scaleBand, scaleLinear, scaleTime } from "d3";
import { SliderAxis } from "./slider-axis";

interface HistogramProps {
  selectedData: any;
  startDate: Date;
  endDate: Date;
  dayRange: number;
}

const Histogram = ({
  selectedData,
  startDate,
  endDate,
  dayRange,
}: HistogramProps) => {
  const [ref, bounds] = useMeasure();
  const chartHeight: number = 27;

  const createHistogramRect = () => {
    const margins = { top: 0, right: 16, bottom: 0, left: 16 };
    const chartWidth = bounds.width - margins.left - margins.right;

    const isTemperature = selectedData.Klimasignal === "Hitzesommer";
    const dataObject: any = isTemperature ? temperatureHist : rainHist;
    const selectedDataObject: any =
      dataObject[`${selectedData.Klimasignal}${selectedData.Zeitraum}`];

    const color = isTemperature ? colors.heat : colors.rain;
    const yDomain = isTemperature ? [25, 42] : [0, 312];
    const yScale = scaleLinear().domain(yDomain).range([chartHeight, 0]);
    const xScale = scaleLinear()
      .domain([0, Object.keys(selectedDataObject).length - 1])
      .range([0, chartWidth]);

    let axisStartDate = new Date(startDate);
    axisStartDate.setHours(0, 0, 0, 0);

    let axisEndDate = new Date(endDate);
    axisEndDate.setHours(0, 0, 0, 0);

    const axisScale = scaleTime()
      .domain([axisStartDate, axisEndDate])
      .range([0, chartWidth]);

    const points = Object.keys(selectedDataObject)
      .map((key: string, index: number) => {
        const datum = selectedDataObject[key];
        return `L${Math.round(xScale(index))},${Math.round(yScale(datum))}`;
      })
      .join(" ");

    return (
      <>
        <g transform={`translate(${margins.left},0)`}>
          <SliderAxis xScale={axisScale} dayRange={dayRange} />
          <path
            d={`M0,${chartHeight} ${points} L${chartWidth},${yScale(0)} Z`}
            fill={color}
            fillOpacity={0.2}
          />
        </g>
      </>
    );
  };

  return (
    <div ref={ref} style={{ width: "100%", marginBottom: "-12px" }}>
      <svg width={bounds.width} height={chartHeight}>
        {selectedData && bounds && createHistogramRect()}
      </svg>
    </div>
  );
};

export default Histogram;
