import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import hand from "../../images/hand.png";
import { breakpoints, colors, sizes } from "../../models";
import { PlayButton } from "./play-button";
import { Slider } from "./slider";

interface TitleProps {
  selectedData: any;
  setDate: React.Dispatch<React.SetStateAction<Date>>;
  playing: boolean;
  setPlaying: React.Dispatch<React.SetStateAction<boolean>>;
  selectedEvents: any;
  isFazit: boolean;
}

const ProcessContainer = styled.div`
  padding: 5px 5px 2px 5px;
  border: 1px solid ${colors.gridLight};
  border-radius: 5px;
  display: flex;
  gap: 0;
  background-color: ${colors.orangeOverlay70};
  backdrop-filter: blur(2px);
  box-shadow: 0px 4px 20px ${colors.blackShadow};

  @media (min-width: ${breakpoints.tabletL}px) {
    padding: 5px 10px 2px 10px;
  }

  @media (min-width: ${breakpoints.desktopS}px) {
    box-shadow: none;
  }
`;

const moveHand = keyframes`
0%{transform: translateX(0); opacity: 1}
90%{transform: translateX(240px); opacity: 1}
100%{transform: translateX(240px); opacity: 0}
`;

const Hand = styled.img`
  width: 70px;
  position: absolute;
  z-index: 101;
  margin-top: 12px;
  margin-left: 17px;
  pointer-events: none;

  animation: ${moveHand} 3s infinite;
`;

export const Process = ({
  selectedData,
  setDate,
  playing,
  setPlaying,
  selectedEvents,
  isFazit,
}: TitleProps) => {
  const [sliderValue, setSliderValue] = useState<number>(0);
  const [count, setCount] = useState<number>(sliderValue);
  const [showHand, setShowHand] = useState<boolean>(false);

  useEffect(() => {
    if (!selectedEvents) return;
    if (selectedEvents.isTitle || isFazit) {
      setSliderValue(0);
      setPlaying(false);
    }
    if (!selectedData) return;

    let beginDate = new Date(selectedData.Beginn);
    let eventDate = new Date(selectedEvents.date);

    const DifferenceInTime = eventDate.getTime() - beginDate.getTime();
    const DifferenceInDays = DifferenceInTime / (1000 * 3600 * 24);

    setSliderValue(DifferenceInDays);
    if (
      selectedData.Klimasignal === "Hitzesommer" &&
      selectedData.Zeitraum != 2003
    ) {
      setShowHand(false);
      setPlaying(true);
      setCount(DifferenceInDays);
    } else if (selectedData.Zeitraum === 2003) {
      setShowHand(true);
    } else {
      setShowHand(false);
      setPlaying(false);
    }
  }, [selectedEvents, selectedData]);

  useEffect(() => {
    if (selectedData) {
      let date = new Date(selectedData.Beginn);
      date.setDate(date.getDate() + sliderValue);

      const endDate = new Date(selectedData.Ende);
      if (date <= endDate) {
        setDate(date);
      } else {
        setPlaying(false);
        setSliderValue(0);
        setDate(new Date(selectedData.Beginn));
      }
    } else if (isFazit) {
      setDate(new Date(selectedEvents.date));
    }
  }, [sliderValue]);

  const handleSliderValue = (value: number) => {
    if (value != undefined) setSliderValue(value);
  };

  return (
    <ProcessContainer>
      <PlayButton
        sliderValue={sliderValue}
        handleSliderValue={handleSliderValue}
        playing={playing}
        setPlaying={setPlaying}
        count={count}
        setCount={setCount}
      />
      <Slider
        selectedData={selectedData}
        sliderValue={sliderValue}
        handleSliderValue={handleSliderValue}
        setPlaying={setPlaying}
        setShowHand={setShowHand}
        showHand={showHand}
      />
      <Hand src={hand} style={{ display: showHand ? "block" : "none" }} />
    </ProcessContainer>
  );
};
