import React, { useRef, useEffect, useState } from "react";
import mapboxgl from "mapbox-gl";
import niederschlag from "../../data/niederschlag-ohne-limit.json";
import temperature from "../../data/high-temperatur-ohne-limit.json";
import stationKlima from "../../data/station-klima.json";
import Station from "./station";

interface StationProps {
  map: any;
  date: Date;
  setSelectedStation: React.Dispatch<React.SetStateAction<string>>;
  selectedCategory: string | undefined;
  setTooltipPosition: React.Dispatch<React.SetStateAction<[number, number]>>;
  setTooltipVisible: React.Dispatch<React.SetStateAction<boolean>>;
  dwdData: Object | undefined;
  setDwdData: React.Dispatch<React.SetStateAction<Object | undefined>>;
}

const Stations = ({
  map,
  date,
  setSelectedStation,
  selectedCategory,
  setTooltipPosition,
  setTooltipVisible,
  dwdData,
  setDwdData,
}: StationProps) => {
  const stations: Object = stationKlima;
  const [stationsVisible, setStationsVisible] = useState<boolean>(false);

  useEffect(() => {
    setDwdData(temperature);
  }, []);

  useEffect(() => {
    if (selectedCategory === "rain") {
      setDwdData(niederschlag);
      setStationsVisible(true);
    } else if (selectedCategory === "temperature") {
      setDwdData(temperature);
      setStationsVisible(true);
    } else {
      setStationsVisible(false);
    }
  }, [selectedCategory]);

  const createStations = () => {
    return (
      <>
        {Object.keys(stations).map((key: any) => {
          return (
            <Station
              key={`${key}-station`}
              map={map}
              date={date}
              station={key}
              stationData={stations[key as keyof typeof stations]}
              data={dwdData && dwdData[key as keyof typeof dwdData]}
              setSelectedStation={setSelectedStation}
              selectedCategory={selectedCategory}
              setTooltipPosition={setTooltipPosition}
              setTooltipVisible={setTooltipVisible}
              stationsVisible={stationsVisible}
            />
          );
        })}
      </>
    );
  };

  return <>{map && createStations()}</>;
};

export default Stations;
